<template>
  <div class="gestion-organisme-th">
    <div class="box-setting-header">
      <div class="title">Gestion des organismes</div>
      <div>
        <b-button
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addOrganisme
        >
          <font-awesome-icon icon="plus" /> Ajouter une organismes
        </b-button>
        <b-modal
          id="addOrganisme"
          ref="addOrganisme"
          :hide-footer="true"
          :hide-header="true"
          no-close-on-backdrop
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap "
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter une organisme</div>
            <div class="iconClose" @click.prevent="hideModal('addOrganisme')">
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="hendleSubmitOrganisme"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label="Nom de l'organsime"
                  label-for="nom"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="nom"
                    v-model="organismeToAdd"
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="actionModel mt-3">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul v-if="Array.isArray(error)">
                        <li v-for="(e, index) in error" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button type="submit" class="button-valide-style ">
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
    </div>
    <div class="ligne-box-setting-header"></div>
    <div class="box-all-filter">
      <div class="box-label-champ mr-2">
        <div class="label-box-style">
          <span class="title-tabel">Année</span>
        </div>
        <b-form-select
          class="b-form-select-new-style"
          v-model="filterYear"
          :options="[{ value: null, text: 'Tous' }, ...ListYear]"
          @change="handleChangeYear"
        ></b-form-select>
      </div>
      <div class="box-label-champ mr-2">
        <div class="label-box-style">
          <span class="title-tabel">Mois</span>
        </div>

        <b-form-select
          class="b-form-select-new-style"
          v-model="filterMonth"
          :options="[
            { value: null, text: 'Tous' },
            ...computedGetMonthForYearTh
          ]"
          text-field="text"
          value-field="value"
          @change="handleFilter"
        ></b-form-select>
      </div>
      <div v-if="getOrganismeThLoading" class="chargement-message-text ml-2">
        Chargement en cours
      </div>
      <div class="error-message">
        <div v-if="getOrganismeThError" class="error">
          <ul v-if="Array.isArray(getOrganismeThError)">
            <li v-for="(e, index) in getOrganismeThError" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ getOrganismeThError }}</div>
        </div>
      </div>
    </div>

    <div class=" organisme-body ">
      <div class="tabs-organisme">
        <div class="organisme-labels">
          <div class="title">Organisme</div>

          <div>
            <font-awesome-icon
              icon="trash"
              class="trash-style"
              v-if="selectedOrganisme"
              v-b-modal.deleteOrganismeModal
            />
          </div>
        </div>
        <b-list-group class="custom-list-group">
          <b-list-group-item
            button
            v-for="organisme in getOrganismeTh"
            :key="organisme.name"
            @click.prevent="handleClickOrganisme(organisme)"
          >
            <div class="list-item">
              <div>
                {{ organisme.name }}
              </div>
              <div
                class="icon"
                v-if="
                  selectedOrganisme && selectedOrganisme.name == organisme.name
                "
              >
                <font-awesome-icon icon="check" />
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>

      <div class="organisme-details" v-if="selectedOrganisme && nameToUpdate">
        <div class="title">
          <b-form-group label="Nom de l'organisme" class="input-modal-champ">
            <b-form-input
              autocomplete="off"
              :value="nameToUpdate.name"
              @input="editOrganismeNameTh($event)"
            />
          </b-form-group>
        </div>
        <template v-if="getOrganismeThDetailsLoading">
          <div class="chargement-message-text ml-2">
            Chargement en cours
          </div>
        </template>
        <template v-else>
          <b-table
            show-empty
            id="my-table"
            class="custom-table-style table-gestion-paies-style"
            :items="selectedOrganisme.depot"
            :fields="fields"
            bordered
            sticky-header
            hover
            head-variant="light"
            tbody-tr-class="ligneNormale"
            empty-text="Il n'y a aucun enregistrement à afficher"
          >
            <template v-slot:cell(pu_commission)="data">
              <editable-input-th
                champName="pu_commission"
                :item="data.item"
                :value="data.item.pu_commission"
                type="text"
                :updateFunction="EditVariableDepotOrganismeTh"
              ></editable-input-th>
            </template>
            <template v-slot:cell(pu_paie)="data">
              <editable-input-th
                champName="pu_paie"
                :item="data.item"
                :value="data.item.pu_paie"
                type="text"
                :updateFunction="EditVariableDepotOrganismeTh"
              ></editable-input-th>
            </template>
            <template v-slot:cell(pu_prime)="data">
              <editable-input-th
                champName="pu_prime"
                :item="data.item"
                :value="data.item.pu_prime"
                type="text"
                :updateFunction="EditVariableDepotOrganismeTh"
              ></editable-input-th>
            </template>
            <template v-slot:cell(pu_suprime)="data">
              <editable-input-th
                champName="pu_suprime"
                :item="data.item"
                :value="data.item.pu_suprime"
                type="text"
                :updateFunction="EditVariableDepotOrganismeTh"
              ></editable-input-th>
            </template> </b-table
        ></template>
      </div>
    </div>
    <b-modal
      ref="deleteOrganismeModal"
      id="deleteOrganismeModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer une Organisme</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('deleteOrganismeModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form
        @submit.prevent="handleSuprimerOrganisme"
        class="form-modal-custom-style"
      >
        <div class="mt-4 ml-3">
          <p>
            Êtes-vous sur de vouloir supprimer cette organisme
            <strong>
              {{ selectedOrganisme ? selectedOrganisme.name : '-' }}
            </strong>
            ?
          </p>
        </div>
        <div class="actionModel">
          <div class="messageError">
            <div v-if="getOrganismeThError" class="error">
              <ul v-if="Array.isArray(getOrganismeThError)">
                <li
                  v-for="(e, index) in getOrganismeThError"
                  :key="'error' + index"
                >
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ getOrganismeThError }}</div>
            </div>
          </div>
          <b-button type="submit" class="button-valide-style mt-1">
            <span>
              Valider
              <div v-if="getOrganismeThLoading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'Gestion-des-organismes',
  data() {
    return {
      organismeToAdd: null,
      error: null,
      loading: false,
      selectedOrganisme: null,
      fields: [
        { key: 'depot', label: 'Numéro de dépot' },
        { key: 'pu_commission', label: 'Pu commission' },
        { key: 'pu_paie', label: 'Pu paie' },
        { key: 'pu_prime', label: 'Pu prime' },
        { key: 'pu_suprime', label: 'Pu suprime' }
      ],
      nameToUpdate: null,
      filterYear: null,
      filterMonth: null,
      ListYear: []
    };
  },
  computed: {
    ...mapGetters([
      'getOrganismeTh',
      'getOrganismeThLoading',
      'getOrganismeThError',
      'getMonthForYearTh',
      'getOrganismeThDetailsLoading'
    ]),
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    }
  },
  methods: {
    ...mapActions([
      'fetchAllOrganismeTh',
      'addNewOrganismeTh',
      'EditOrganismeNameTh',
      'EditVariableDepotOrganismeTh',
      'deletOrganismeTh',
      'fetchMonthForYearTH',
      'fetchOrganismeThDetails'
    ]),
    resetModal() {
      this.organismeToAdd = null;
      this.error = null;
      this.loading = false;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async hendleSubmitOrganisme() {
      this.loading = true;
      const response = await this.addNewOrganismeTh(this.organismeToAdd);
      if (response.succes) {
        this.loading = false;
        this.error = null;
        this.hideModal('addOrganisme');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    handleClickOrganisme(organisme) {
      this.selectedOrganisme = organisme;
      this.nameToUpdate = { ...this.selectedOrganisme };
      this.handleFilter();
    },
    editOrganismeNameTh: _.debounce(function(e) {
      let params = { ...this.selectedOrganisme };
      params.name = e;
      this.EditOrganismeNameTh(params);
    }, 500),
    async handleSuprimerOrganisme() {
      const response = await this.deletOrganismeTh(this.selectedOrganisme);
      if (response) {
        this.selectedOrganisme = null;
        this.hideModal('deleteOrganismeModal');
      }
    },
    handleFilter() {
      if (this.selectedOrganisme) {
        this.fetchOrganismeThDetails({
          year: this.filterYear,
          month: this.filterMonth,
          organisme: this.selectedOrganisme
        });
      }
    },
    handleChangeYear() {
      this.filterMonth = null;
      this.fetchMonthForYearTH(this.filterYear);
      this.handleFilter();
    }
  },
  components: {
    Card: () => import('../component/card'),
    editableInputTh: () => import('../component/EditableInputTh.vue')
  },
  async mounted() {
    var courantYear = moment().year();
    this.filteryear = courantYear;
    await this.fetchMonthForYearTH(this.filteryear);
    var courantMonthId = moment().month();
    var courantMonth = this.getMonthForYearTh[courantMonthId]
      ? this.getMonthForYearTh[courantMonthId].month_name
      : this.filterMonth;
    this.filterMonth = courantMonth;
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    // if (this.filterYear != null) {
    //   this.fetchMonthForYearTH(this.filterYear);
    // }
    this.fetchAllOrganismeTh();
  }
};
</script>

<style lang="scss" scoped>
.gestion-organisme-th {
  height: 100%;
  .tabs-organisme {
    width: 25%;
    margin: 5px 15px;
    .custom-list-group {
      .list-group-item {
        justify-content: space-between;
        color: #515356;
        display: block;
        font-weight: 600;
        .list-item {
          justify-content: space-between;
        }
      }
    }
    .title {
      color: #515356;
      display: block;
      font-weight: 600;
      margin: 0px 0px 10px 0px;
    }
    .organisme-labels {
      display: flex;
      justify-content: space-between;
    }
    .trash-style {
      margin-left: 5px;
      color: #dc3545;
    }
  }

  .organisme-body {
    display: flex;
    margin: 5px 15px;
    height: calc(100vh - 86px);
    .organisme-details {
      width: 75%;
      .title {
        color: #515356;
        display: block;
        font-weight: 600;
        margin: 5px;
      }
      .input-modal-champ {
        input {
          border-radius: 5px;
        }
      }
      .custom-table-style {
        padding: 5px;
        max-height: calc(100vh - 198px);
        height: calc(100% - 198px);
      }
    }
  }
  .ligne-box-setting-header {
    margin-top: 1%;
  }
}
</style>
